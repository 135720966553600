<template>
  <board-list-header
      :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :list-total-count="paging.totalCount"
  />

  <LoadingDiv v-if="isLoading" />
  <div v-else class="board-list-container">
    <ul class="board-list">
      <li class="board-list-item" v-if="posts.length == 0"><p class="text-center fw-bolder text-muted">등록된 게시글이 없습니다.</p></li>
      <li class="board-list-item" v-for="(item, idx) in posts" :key="idx">
        <article v-if="item.stt == '11'" class="board-row"><p class="text-muted">운영자에 의해 삭제된 게시글입니다.</p></article>
        <article v-else class="board-row kb-mouse-cursor" :class="{'opacity-50': item.isRead === 'Y'}" @click="clickDtl(item)">
          <div v-if="item.postLvl > 0">
            <div class="board-column column-answer" :style="{'width':`${item.postLvl*2+2}rem`}"><span class="text">답글</span></div>
          </div>
          <div v-else>
            <div v-if="headers.includes('seq')" class="board-column column-index">
              <span v-if="item.topFixYn == 'Y'" class="text text-gold">필독</span>
              <em v-else class="text">{{item.postIdx}}</em>
            </div>
          </div>
          <div v-if="headers.includes('category')" class="board-column column-category opacity-100"><span class="text text-gold">{{item.cateNm}}</span></div>
          <div v-if="headers.includes('fileAtch') && item.postFileAtchYn == 'Y'" class="board-column column-file"><i class="icon-file"/></div>
          <div v-if="headers.includes('title')" class="board-column column-title" :title="checkScrtMd(boardMst, item, session.lrnerId == item.rgtrId, isOper) ? '비공개' : item.postTitle">
            <template v-for="badge in item.badges" :key="badge.boardBadgeSn">
              <div class="badge badge-red me-1"><span class="badge-text">{{badge.postBadgeNm}}</span></div>
            </template>
            <span class="title text-black">{{checkScrtMd(boardMst, item, session.lrnerId == item.rgtrId, isOper) ? '비공개 게시글 입니다.' : item.postTitle}}</span>
            <i v-if="boardMst.newBadgeYn === 'Y' && item.isNew === 'Y'" class="icon-new"></i>
          </div>
          <div v-if="headers.includes('rgtrId')" class="board-column column-author opacity-100"><span class="text text-black">{{(boardMst.userScrtYn == 'Y') ? item.userNickNm : item.lrnerNm + ' | ' + item.deptNm}}</span></div>
          <div v-if="headers.includes('regDt')" class="board-column column-date opacity-100"><span class="text text-black">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
          <div v-if="headers.includes('inqCnt')" class="board-column column-icon opacity-100"><i class="icon-view me-1" /><span class="text text-black">{{item.inqCnt}}</span></div>
          <div v-if="headers.includes('likeCnt')" class="board-column column-icon opacity-100"><i class="icon-like me-1" :class="{'is-active': item.isLike == 'Y'}"/><span class="text text-black">{{item.likeCnt}}</span></div>
        </article>
      </li>
    </ul>
    <CommonPaginationFront :paging="paging" :page-func="pagingFunc" v-if="isPaging" />
  </div>

</template>

<script>
import BoardListHeader from "@/components/board/BoardListHeader";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {boardMainListSetup} from "@/assets/js/modules/board/board-common";

export default {
  name: 'BoardMainList',
  components: {BoardListHeader, CommonPaginationFront, LoadingDiv},
  props: {
    boardMstMap: Object,
    boardCateList: Array,
    isOper: Boolean,
    postGrpNo: String,
  },
  setup: boardMainListSetup
}
</script>

<style>
.board-column.column-answer .text {
  font: var(--kb-body16-fontB);
  color: var(--kb-yellow);
}
i.icon-arrow {
  width: 24px;
  height: 24px;
  background-image:url(../../assets/lxp/images/common/ic_arrow16_right.svg);
}
</style>