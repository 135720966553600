<template>
  <div class="list-top">
    <div class="top-column ps-6">
      <p class="title text-muted" style="min-width: 70px">총 {{ listTotalCount }}건</p>
      <template v-if="showHottipHeader">
        <div v-if="boardMst.boardId != 'hottip-hq'" class="kb-form-check ms-3">
          <input v-model="search.isBestHotTip" class="kb-form-check-input" type="checkbox" id="board-list-header-hottip-best" :value="true" @change="clickSearch" />
          <label class="kb-form-check-label" for="board-list-header-hottip-best">우수 Tip</label>
        </div>
        <div class="kb-form-check ms-3">
          <input v-model="search.isLike" class="kb-form-check-input" type="checkbox" id="board-list-header-hottip-like" :value="true" @change="clickSearch" />
          <label class="kb-form-check-label" for="board-list-header-hottip-like">좋아요한 Tip</label>
        </div>
      </template>
    </div>
    <div class="top-column">
      <!-- 카테고리 필터 -->
      <div v-if="boardMst.srchCateYn == 'Y'" class="header-dropdown">
        <div class="dropdown" :class="{ 'is-active': toggle.category }" @click="toggle.category = !toggle.category">
          <button class="dropdown-btn kb-btn kb-btn-outline"><strong class="text">{{search.category.cateNm}}</strong><i class="icon-arrow"></i></button>
          <div class="dropdown-target">
            <div class="dropdown-box max-h-500 overflow-y-auto">
              <ul class="dropdown-option-list">
                <li class="dropdown-option-item" v-for="item in boardCateList" :key="item.boardCateSn">
                  <a class="dropdown-option-link" @click="clickCategory(item)"><strong class="dropdown-option-text">{{item.cateNm}}</strong></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 제목 검색 -->
      <div v-if="boardMst.srchTitleYn == 'Y'" class="kb-form-search">
        <div class="kb-form-search-field">
          <input type="text" class="kb-form-search-input" placeholder="검색어를 입력하세요"
                 v-model="search.word"
                 @keyup.enter="clickSearch"
          >
          <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
        </div>
      </div>
      <!-- 글쓰기 -->
      <div v-if="boardMst.postWrtYn == 'Y' && validTarget(boardMst.postWrtTrgt, isOper)"><button class="kb-btn kb-btn-outline rounded-lg" @click="clickWrite">글쓰기</button></div>
    </div>
  </div>
</template>

<script>
import {ref, computed, reactive, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {goToWrite, validTarget} from "@/assets/js/modules/board/board-common";
import {HotTipBoardBadgeCdDcd, hotTipBoardIds} from "@/assets/js/modules/learn/learn-hot-tip";

export default {
  name: 'BoardListHeader',
  props: {
    boardMstMap: Object,
    boardCateList: Array,
    isOper: Boolean,
    listTotalCount: Number,
    paging: Object,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const showHottipHeader = computed(() => hotTipBoardIds.includes(route.params.boardId));
    const boardMst = ref(props.boardMstMap);
    const toggle = reactive({
      category: false,
    });
    const search = reactive({
      category: {cateNm:'카테고리', boardCateSn:''},
      word: '',
      isLike: false,
      isBestHotTip: false,
    });

    const clickCategory = (item) => {
      search.category.cateNm = item.cateNm;
      search.category.boardCateSn = item.boardCateSn;
      clickSearch();
    }

    const clickSearch = () => {
      let queryParam = {...route.query};
      queryParam['pageNo'] = 1;
      queryParam['isLike'] = search.isLike ? 'Y' : '';
      queryParam['postBadgeCdDcd'] = search.isBestHotTip ? HotTipBoardBadgeCdDcd.BEST : '';
      queryParam['boardCateSn'] = search.category.boardCateSn;
      queryParam['word'] = search.word;

      router.push({query: queryParam});
    }

    const clickWrite = () => {
      goToWrite(session.value.lrnerId, boardMst);
    }

    onMounted(() => {
      if(route.query.boardCateSn) {
        search.category.boardCateSn = Number(route.query.boardCateSn);
        search.category.cateNm = props.boardCateList.find(x => x.boardCateSn === search.category.boardCateSn).cateNm;
      }
      search.word = route.query.word;
      search.isLike = (route.query.isLike == 'Y');
      search.isBestHotTip = (route.query.postBadgeCdDcd == HotTipBoardBadgeCdDcd.BEST);
    })


    return {
      boardMst,
      showHottipHeader,
      toggle,
      search,
      clickCategory,
      clickSearch,
      clickWrite,
      validTarget,
    }
  }
}
</script>