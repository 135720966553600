<template v-if="!isLoading">
  <board-list-header
      :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :list-total-count="paging.totalCount"
  />

  <div class="board-list-container">
    <div class="board-timeline-wrapper">
      <ul class="board-list">
        <li v-if="posts.length == 0"><p class="text-center fw-bolder text-muted">등록된 게시글이 없습니다.</p></li>
        <li class="board-list-item" v-for="(post, idx) in posts" :key="idx">
          <article v-if="post.stt == '11'" class="board-timeline p-sm-4"><p class="text-center fw-bolder text-muted">운영자에 의해 삭제된 게시글입니다.</p></article>
          <article v-else-if="checkScrtMd(boardMst, post, session.lrnerId == post.rgtrId, isOper)" class="board-timeline p-sm-4"><p class="text-center fw-bolder text-muted">비공개 게시글 입니다.</p></article>
          <article v-else-if="post.postLvl == '0'">
            <div v-if="boardMst.ansWrtYn == 'Y' && validTarget(boardMst.ansWrtTrgt, isOper)" class="float-end" style="margin-top:-60px;"><button class="kb-btn kb-btn-outline rounded-lg" @click="addAnswer(idx)">글쓰기</button></div>
            <div class="board-content ck-content m-sm-4" v-html="post.postCn"></div>
          </article>
          <article v-else class="board-row kb-mouse-cursor" @click="clickDtl(post)">
            <div v-if="headers.includes('category')" class="board-column column-category"><span class="text">{{post.cateNm}}</span></div>
            <div v-if="headers.includes('fileAtch') && post.postFileAtchYn == 'Y'" class="board-column column-file"><i class="icon-file"/></div>
            <div v-if="headers.includes('title')" class="board-column column-title" :title="checkScrtMd(boardMst,post,session.lrnerId == post.rgtrId, isOper) ? '비공개' : post.postTitle">
              <span class="title">{{checkScrtMd(boardMst,post,session.lrnerId == post.rgtrId, isOper) ? '비공개 게시글 입니다.' : post.postTitle}}</span>
            </div>
            <div v-if="headers.includes('rgtrId')" class="board-column column-author"><span class="text">{{(boardMst.userScrtYn == 'Y') ? post.userNickNm : post.lrnerNm + ' | ' + post.deptNm}}</span></div>
            <div v-if="headers.includes('regDt')" class="board-column column-date"><span class="text">{{timestampToDateFormat(post.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
            <div v-if="headers.includes('inqCnt')" class="board-column column-hit"><span class="text">{{post.inqCnt}} 조회</span></div>
            <div v-if="headers.includes('likeCnt')" class="board-column column-hit"><span class="text">좋아요 {{post.likeCnt}}</span></div>
          </article>
        </li>
      </ul>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc" v-if="isPaging" />
    </div>
  </div>
</template>

<script>
import BoardListHeader from "@/components/board/BoardListHeader";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {BoardGroupListSetup} from "@/assets/js/modules/board/board-common";

export default {
  name: "BoardGroupList",
  props: {
    boardMstMap: Object,
    boardCateList: Array,
    isOper: Boolean,
    postGrpNo: String,
  },
  components: {BoardListHeader, CommonPaginationFront,},
  setup: BoardGroupListSetup
}
</script>